import React from 'react'
import Layout from 'components/layout'
import styled from '@emotion/styled'
// import { css } from '@emotion/core'
// import { rem } from 'utils/typography'
//
import SecondaryParagraph from 'components/text/secondary-paragraph'

const SecondPage = props => {
  return (
    <Layout>
      <Container>
        <h1>Thank you 🎉</h1>
        <p>You have successfully subscribed to Toast.</p>
        <SecondaryParagraph>
          Contact <a href="mailto:support@toast.ninja">support@toast.ninja</a>&nbsp; or just &nbsp;
          <Code>/toast ask</Code> in Slack if you have any questions.
        </SecondaryParagraph>
      </Container>
    </Layout>
  )
}

const Code = styled.span`
  padding: 3px;
  color: #404040;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export default SecondPage
